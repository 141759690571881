import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Security } from '../utils/security.util';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  constructor(private router: Router, private http: HttpClient) { }

  canActivate(): Observable<boolean> | boolean {
    if (!Security.hasName()) {
      Security.clear();
      this.router.navigate(['/login']);
      return false;
    }

    if (!Security.hasToken()) {
      Security.clear();
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

  validaToken() {
    return this.http.post(environment.serviceUrl + 'v1/token/valid', null, this.options());
  }

  authenticate(data) {
    return this.http.post(environment.serviceUrl + 'v1/token', data, this.simpleOptions());
  }

  options() {
    const headers = new HttpHeaders()
    .set('Authorization', `Bearer ${Security.getToken()}`)
    .set('api-version', Security.versionApi());
  return { headers };
  }

  simpleOptions(){
    const headers = new HttpHeaders()
    .set('api-version', Security.versionApi());
  return { headers };
  }
}
