import { Component, OnInit } from '@angular/core';

import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { NavController, Platform } from '@ionic/angular';

import { LanguageService } from './services/language.service';
import { Security } from './utils/security.util';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private navCtr: NavController,
    private platform: Platform,
    private statusBar: StatusBar,
    private languageService: LanguageService,
  ) { }

  ngOnInit() {
    this.initializeApp();
    if (!Security.hasIntro()) {
      // this.navCtr.navigateForward(['/intro']);
      this.navCtr.navigateForward(['/']);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.overlaysWebView(true);
      this.statusBar.backgroundColorByHexString('#4caf50'); //cor principal do app

      this.languageService.setInitialAppLanguage();
    });
  }

  goToTab(tab: string) {
    this.navCtr.navigateRoot([tab], { animationDirection: 'forward' });
  }

}
