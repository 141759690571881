export class Security {
  public static set(data) {
    sessionStorage.setItem('sm.id', data.id);
    sessionStorage.setItem('sm.name', data.nick);
    sessionStorage.setItem('sm.cpf', data.document);
    sessionStorage.setItem('sm.token', data.accessToken);
    sessionStorage.setItem('sm.standard', data.standard);
  }

  public static versionApp() {
    return 'PRO2024621195732';
  }

  public static versionApi(){
    return '1.0.0';
  }

  public static getToken() {
    const data = sessionStorage.getItem('sm.token');
    if (data) {
      return data;
    } else {
      return null;
    }
  }

  public static hasName() {
    const data = sessionStorage.getItem('sm.name');
    if (data) {
      return data;
    } else {
      return null;
    }
  }

  public static hasToken(): boolean {
    if (this.getToken()) {
      return true;
    }
    return false;
  }
  public static getStandard() {
    return sessionStorage.getItem('sm.standard');
  }

  public static getDocument() {
    return sessionStorage.getItem('sm.cpf');
  }

  public static getName() {
    return sessionStorage.getItem('sm.name');
  }

  public static getId() {
    return sessionStorage.getItem('sm.id');
  }

  public static clear() {
    sessionStorage.removeItem('sm.name');
    sessionStorage.removeItem('sm.token');
    sessionStorage.removeItem('sm.cpf');
    sessionStorage.removeItem('sm.id');
    localStorage.removeItem('sm.coin');
    localStorage.removeItem('sm.theme');
    localStorage.removeItem('sm.int');
    localStorage.removeItem('sm.language');
  }

  public static setChangeTheme(theme) {
    localStorage.setItem('sm.theme', theme);
  }

  public static getChangeTheme() {
    return localStorage.getItem('sm.theme');
  }

  public static hasTheme(): boolean {
    if (this.getChangeTheme()) {
      return true;
    }
    return false;
  }

  public static hasIntro(): boolean {
    if (this.getIntro()) {
      return true;
    } else {
      return false;
    }
  }

  public static setProjectKey(data) {
    localStorage.setItem('sm.projectKey', data);
  }

  public static getProjectKey() {
    return localStorage.getItem('sm.projectKey');
  }


  public static setCoin(coin) {
    localStorage.setItem('sm.coin', coin);
  }

  public static getCoin() {
    return localStorage.getItem('sm.coin');
  }

  public static setIntro(data) {
    localStorage.setItem('sm.int', data);
  }

  public static getIntro() {
    return localStorage.getItem('sm.int');
  }

  //Preferencia de idioma
  public static hasLanguage(): boolean {
    if (this.getLanguage()) {
      return true;
    } else {
      return false;
    }
  }

  public static setLanguage(data) {
    localStorage.setItem('sm.language', data);
  }

  public static getLanguage() {
    return localStorage.getItem('sm.language');
  }
}
