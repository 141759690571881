// import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Security } from '../utils/security.util';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = '';

  constructor(private translate: TranslateService) { }

  setInitialAppLanguage() {

    if (Security.hasLanguage()) {
      this.setLanguage(Security.getLanguage());
    } else {

      const language = this.translate.getBrowserLang();
      this.translate.setDefaultLang(language);

      Security.setLanguage(language);
      this.selected = language;

    }
  }

  getLanguages() {
    return [
      { text: 'Portugues', value: 'pt', img: 'assets/img/pt.png' },
      { text: 'Espanhol', value: 'es', img: 'assets/img/pt.png' },
      { text: 'English', value: 'en', img: 'assets/img/pt.png' },
    ];
  }

  setLanguage(lng) {
    this.translate.use(lng);
    this.selected = lng;
    Security.setLanguage(lng);
  }
}
